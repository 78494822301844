import React from 'react';
import { imgBrand } from '../../../lib/brand';
import styles from './styles.module.less';

var marker = imgBrand('marker.png', 'SearchMap');

interface MarkerProps {
   slug: string;
   lat: number; // lat/lng aren't used, but are required for map to work (google must be sniffing dom?)
   lng: number;
}

const Marker = (props: MarkerProps) => {
   const { slug } = props;
   return (
      <a className={styles.Marker} href={slug}>
         <div className={styles.container} style={{ backgroundImage: `url(${marker})` }}></div>
      </a>
   );
};

export default Marker;
