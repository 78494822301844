import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleMapApiKey } from '../../lib/env';
import Marker from './Marker';

interface SearchMapProps {
   locations: Array<{
      lat: number;
      lon: number;
      slug: string;
   }>;
}

interface ApiMaps {
   map: any;
   maps: any;
   ref: Element | null;
}

const SearchMap = (props: SearchMapProps) => {
   const { locations = [] } = props;
   const [map, setMap] = useState<any>(null);
   const [maps, setMaps] = useState<any>(null);

   if (locations.length == 0) return null;

   useEffect(() => {
      fit();
   }, [locations, map, maps]);

   const apiLoaded = (apiMaps: ApiMaps) => {
      const { map, maps } = apiMaps;
      setMap(map);
      setMaps(maps);
      fit();
   };

   const fit = () => {
      if (!maps) return;
      if (locations.length === 1) return;
      const bounds = new maps.LatLngBounds();
      locations.forEach(loc => {
         bounds.extend(new maps.LatLng(loc.lat, loc.lon));
      });
      map.fitBounds(bounds);
   };

   return (
      // Important! Always set the container height explicitly
      <section style={{ height: '345px', width: '100%' }}>
         <GoogleMapReact
            bootstrapURLKeys={{ key: googleMapApiKey }}
            defaultCenter={{ lat: locations[0].lat, lng: locations[0].lon }}
            center={{ lat: locations[0].lat, lng: locations[0].lon }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={apiLoaded}>
            {locations.map((loc, i) => (
               <Marker key={i} slug={loc.slug} lat={loc.lat} lng={loc.lon} />
            ))}
         </GoogleMapReact>
      </section>
   );
};

export default SearchMap;
